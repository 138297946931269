<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col cols="5">
        <v-row>
          <v-col
            cols="12"
            class="d-flex">
            <h1 class="text-decoration-underline">
              ข้อมูลสมาชิก
            </h1>
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            ประเภทสมาชิก:
          </v-col>
          <v-col
            cols="8"
            class="profile-col text-uppercase">
            {{ avoidNullValue(member, 'type') }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            เบอร์โทร:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ avoidNullValue(member, 'tel') }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            ชื่อ-นามสกุล:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ `${avoidNullValue(member, 'firstName', '')} ${avoidNullValue(member, 'lastName', '')}` }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            วันเกิด:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ dateCompute(member.birthDate) }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            เพศ:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ genderCompute }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            อีเมล:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ avoidNullValue(member, 'email') }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            ยอดซื้อปัจจุบัน:
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ avoidNullValue(avoidNullValue(member, 'spending'), 'current', 0) | showNumberFormat() }}
          </v-col>
          <v-col
            cols="4"
            class="profile-col">
            ยอดส่วนลดสะสม
          </v-col>
          <v-col
            cols="8"
            class="profile-col">
            {{ calQuotaDiscount | showNumberFormat() }} / 2,000
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-card>
          <v-tabs
            v-model="tabState"
            background-color="primary"
            dark>
            <v-tab>ประวัติการสั่งซื้อ</v-tab>
            <v-tab>โค้ดของลูกค้า</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabState">
            <v-tab-item>
              <v-card flat>
                <spending-log-table
                  v-model="spendingQuery"
                  :loading="spendingsLoading"
                  :items="spendingLog" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <promotions-table
                  v-model="promotionsQuery"
                  :loading="promotionsLoading"
                  :items="promotionCodes" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import PromotionProvider from '@/resources/PromotionProvider'
import SpendingLogTable from '../components/SpendingLogTable.vue'
import PromotionsTable from '../components/PromotionsTable.vue'

const MemberService = new MemberProvider()
const PromotionService = new PromotionProvider()

export default {
  components: {
    SpendingLogTable,
    PromotionsTable
  },
  data () {
    return {
      tabState: null,
      member: {},
      promotionQuotaUsed: 0,
      spendingLog: [],
      promotionCodes: [],
      spendingsLoading: false,
      promotionsLoading: false,
      spendingQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['order.createTime'],
        sortDesc: [true]
      },
      promotionsQuery: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdBy'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    genderCompute () {
      let result = 'ไม่ระบุ'

      if (this.member.gender && this.member.gender === 'male') {
        result = 'ชาย'
      } else if (this.member.gender && this.member.gender === 'female') {
        result = 'หญิง'
      }

      return result
    },
    calQuotaDiscount () {
      if (this.member) {
        return this.promotionQuotaUsed > 0 ? this.promotionQuotaUsed : 0
      }

      return 0
    }
  },
  watch: {
    spendingQuery: {
      async handler () {
        if (!this.member?.id) {
          return
        }

        this.spendingsLoading = true

        await this.getSpendings()

        this.spendingsLoading = false
      },
      deep: true
    },
    promotionsQuery: {
      async handler () {
        if (!this.member?.id) {
          return
        }

        this.promotionsLoading = true

        await this.getPromotions()

        this.promotionsLoading = false
      },
      deep: true
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY') : '-'
    },
    async getMember () {
      try {
        const { data: member } = await MemberService.getMemberByUid(this.$route.params.id)

        this.member = member
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-MEMBER-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getMemberDiscountQuota () {
      try {
        const { data } = await PromotionService.getMemberCampaignUse(this.member.id)
        this.promotionQuotaUsed = data
      } catch (error) {
        console.error('error', error)
      }
    },
    async getPromotions () {
      try {
        const { data: promotionCodes } = await PromotionService.getPromotionByMemberPaginate(this.member.id, this.promotionsQuery)
        this.promotionCodes = promotionCodes.results
        this.promotionsQuery.total = promotionCodes.total
        this.promotionsQuery.pages = promotionCodes.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-PROMOTIONS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getSpendings () {
      try {
        const { data: spendingLog } = await MemberService.getOrdersByMemberId(this.member.id, this.spendingQuery)
        this.spendingLog = spendingLog.results
        this.spendingQuery.total = spendingLog.total
        this.spendingQuery.pages = spendingLog.pages
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING-SPENDING-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async initData () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING MEMBER INFO...'
        })

        await this.getMember()
        await Promise.all([
          this.getMemberDiscountQuota(),
          this.getPromotions(),
          this.getSpendings()
        ])
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
